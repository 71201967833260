import { FORM_ERROR } from 'final-form';
import { DateTime } from 'luxon';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import isEmail from 'shared/utils/is-email';
import Button from 'shared/button';
import Callout from 'shared/callout';
import { LabeledCheckbox } from 'shared/checkbox';
import countryToCurrency from 'shared/country-code-to-currency';
import FormError from 'shared/form-error';
import { Box, Flex, Grid } from 'shared/grid';
import { useLibPhoneNumber } from 'shared/hooks/phone-number';
import Input from 'shared/input';
import Label from 'shared/label';
import Modal, { CloseButton, StandardModal } from 'shared/modal';
import PhoneInput from 'shared/phone-input';
import { addToast } from 'shared/toaster/redux/actions';
import TOS from 'shared/tos.md';
import { Heading, Link, Text } from 'shared/typography';
import { getCookie } from 'shared/utils/cookies';
import { getScrollToErrors } from 'shared/utils/form';
import axios from '../anaxios';
import Autocomplete from '../autocomplete';
import ForgotPasswordModal from '../forgot-password-modal';
import { getRecaptchaToken, useIsRecaptchaReady } from '../hooks/use-recaptcha';
import InputGroup from '../input-group';
import settings from '../settings';
import { IN_READ_ONLY_MODE } from '../utils/constants';
import { isUUID } from '../utils/uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const COOKIE_FIELDS = [
  'affiliate_id',
  'offer_code',
  'utm_content',
  'utm_campaign',
  'utm_medium',
  'utm_source',
  'utm_term',
] as const;

interface CookieFields {
  affiliate_id?: string;
  offer_code?: string;
  utm_content?: string;
  utm_campaign?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
}

const SignupModal = ({ isOpen, close }) => {
  const dispatch = useDispatch<any>();
  const timezone = DateTime.local().zoneName;
  const countryCode = useSelector((state: any) => state.geoip.country_code);
  const currency = countryToCurrency[countryCode] || 'USD';
  const decorators = React.useMemo(() => [getScrollToErrors()], []);
  const [templateBusinesses, setTemplateBusinesses] = React.useState([]);
  const { isValidNumber } = useLibPhoneNumber();

  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    React.useState(false);

  React.useEffect(() => {
    axios.get(`${settings.api2Root}/template-businesses`).then((res) => {
      setTemplateBusinesses(
        res.data.items.map((v) => ({ value: v.name, label: v.label }))
      );
    });
  }, []);

  const businessTypes = React.useMemo(() => {
    const types = [...templateBusinesses].sort((a, b) =>
      a.value.startsWith('other') ? 1 : a.label < b.label ? -1 : 1
    );

    return types;
  }, [templateBusinesses]);

  const isRecaptchaReady = useIsRecaptchaReady();

  const {
    affiliate_id,
    offer_code,
    utm_content,
    utm_campaign,
    utm_medium,
    utm_source,
    utm_term,
  } = React.useMemo(() => {
    if (typeof window === 'undefined') {
      return {};
    }

    const cookieFields = COOKIE_FIELDS.reduce((acc, key) => {
      const value = getCookie(key);

      if (value) {
        acc[key] = value;
      }

      return acc;
    }, {});

    if (cookieFields['affiliate_id'] && !isUUID(cookieFields['affiliate_id'])) {
      delete cookieFields['affiliate_id'];
    }

    return cookieFields as CookieFields;
  }, []);

  const signup = React.useCallback(
    async ({
      firstname,
      lastname,
      email,
      cellphone,
      business_name,
      type,
      other_type,
      offer_id,
      referring_business_id,
      affiliate_id,
      offer_description,
      utm_content,
      utm_campaign,
      utm_medium,
      utm_source,
      utm_term,
    }: {
      firstname?: string;
      lastname?: string;
      email: string;
      cellphone?: string;
      business_name: string;
      type: string;
      other_type?: string;
      offer_id?: string;
      referring_business_id?: string;
      affiliate_id?: string;
      offer_description?: string;
      utm_content?: string;
      utm_campaign?: string;
      utm_medium?: string;
      utm_source?: string;
      utm_term?: string;
    }) => {
      if (email.toLowerCase().endsWith('stu.ukzn.ac.za')) {
        return {
          [FORM_ERROR]: (
            <Grid>
              <Text as="p">
                It looks like you are trying to sign up for an account with
                bookem.com instead of booking with one of UKZN's counsellors.
              </Text>
              <Text as="p">
                To book with a counsellor, you need to go to one of the
                following links, depending on what department you're with:{' '}
              </Text>

              <Box as="ul">
                <Box as="li">
                  <Link href="https://ukzn.ac.za/psychology" target="_blank">
                    CAES
                  </Link>
                </Box>
                <Box as="li">
                  <Link href="https://clms-ukzn.bookem.com/" target="_blank">
                    CLMS
                  </Link>
                </Box>
                <Box as="li">
                  <Link href="httpss://ukzn-casse.bookem.com" target="_blank">
                    CASSE
                  </Link>
                </Box>
              </Box>

              <Text as="p">
                Let us know if you don't come right, or if you did indeed intend
                to sign up for bookem.com
              </Text>
            </Grid>
          ),
        };
      }

      const recaptchaToken = await getRecaptchaToken('SIGNUP');

      return axios
        .post(
          `${settings.api2Root}/signup`,
          {
            utm_content,
            utm_campaign,
            utm_medium,
            utm_source,
            utm_term,
            firstname,
            lastname,
            email_address: email,
            phone_number: cellphone,
            business_name,
            timezone,
            currency,
            country: countryCode,
            type,
            other_type,
            captcha_token: recaptchaToken,
            offer_id,
            referring_business_id,
            affiliate_id,
          },
          { skipErrorResponseIntercept: true }
        )
        .then(({ data: { token, user_id } }) => {
          const heap = window['heap'];

          if (window['heap']) {
            heap.identify(user_id);
            heap.addUserProperties({
              Name: `${firstname} ${lastname}`,
              Email: email,
            });

            heap.track('Sign up');
          }

          window.location.assign(settings.getUrl('app', `/app?token=${token}`));

          return new Promise((resolve) => null); // never resolve so we cant double submit
        })
        .catch(({ response }) => {
          if (response?.status === 409) {
            // dispatch(
            //   addToast(
            //     'A user with this email address already exists',
            //     'warning'
            //   )
            // );
            return {
              [FORM_ERROR]: (
                <>
                  A this email address already exists, log in to create a new
                  business, or{' '}
                  <Link
                    as="button"
                    type="button"
                    onClick={() => setIsForgotPasswordModalOpen(true)}
                  >
                    recover your account
                  </Link>
                </>
              ),
            };
          } else {
            dispatch(addToast('Signup failed', 'warning'));
          }
        });
    },
    [countryCode, currency, dispatch, timezone]
  );

  const [isTOSOpen, setIsTOSOpen] = React.useState(false);

  const initialValues = React.useMemo(() => {
    return {
      affiliate_id,
      utm_content,
      utm_campaign,
      utm_medium,
      utm_source,
      utm_term,
    };
  }, [
    affiliate_id,
    utm_campaign,
    utm_content,
    utm_medium,
    utm_source,
    utm_term,
  ]);

  const initialOfferValues = React.useMemo(() => {
    return {
      discount_code: offer_code,
    };
  }, [offer_code]);

  const [isDiscountCodeOpen, setIsDiscountCodeOpen] = React.useState(
    !!offer_code
  );

  React.useEffect(() => {
    if (offer_code && isOpen) {
      setTimeout(() => {
        const el = document.getElementById('discount-code-apply-button');
        el?.click();
      });
    }
  }, [offer_code, isOpen]);

  // const formRef = React.useRef(null);

  // const applyOfferCode = React.useCallback((value) => {
  //   const form = formRef.current;
  //   return axios
  //     .post(
  //       `${settings.api2Root}/system-offer-code-validate`,
  //       { value },
  //       { skipErrorResponseIntercept: true }
  //     )
  //     .then(
  //       ({ data }) => {
  //         for (const key in data) {
  //           // @ts-ignore
  //           form.change(key, data[key]);
  //         }
  //         setIsDiscountCodeOpen(false);
  //       },
  //       (error) => {
  //         if (typeof error.response?.data?.detail === 'string') {
  //           return {
  //             discount_code: error.response.data.detail,
  //           };
  //         }
  //         return {
  //           discount_code: 'Invalid code',
  //         };
  //       }
  //     );
  // }, []);

  if (IN_READ_ONLY_MODE) {
    return (
      <StandardModal isOpen={isOpen} close={close} title="Signups disabled">
        {() => <Box>Signups are disabled for maintenance</Box>}
      </StandardModal>
    );
  }

  return (
    <Modal
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={false}
      isOpen={isOpen}
      close={close}
      size="small"
    >
      {({ close }) => (
        <>
          <Form
            decorators={decorators}
            onSubmit={signup}
            initialValues={initialValues}
          >
            {({
              handleSubmit,
              submitError,
              submitting,
              dirtySinceLastSubmit,
              values,
              form,
            }) => {
              // formRef.current = form;

              return (
                <Grid
                  p={4}
                  gridGap={4}
                  as="form"
                  name="signup"
                  onSubmit={handleSubmit}
                  noValidate
                >
                  <Grid gridGap={1}>
                    <Flex alignItems="center" justifyContent="space-between">
                      <CloseButton onClick={() => null} />
                      <Heading textAlign="center">Get your account</Heading>
                      <CloseButton isDark={true} onClick={close} />
                    </Flex>
                    <Flex alignItems="center" justifyContent="center">
                      <Box>
                        Already have an account?{' '}
                        <Link
                          fontWeight="heading"
                          onClick={close}
                          as="a"
                          href={
                            __TARGET_ENV__ === 'production'
                              ? 'https://app.bookem.com/app'
                              : settings.getUrl('app', '/app')
                          }
                        >
                          Sign in here
                        </Link>
                        .
                      </Box>
                    </Flex>
                  </Grid>

                  <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gridGap={3}>
                    {submitError && !dirtySinceLastSubmit && (
                      <Callout color="alert" gridColumn="1/-1">
                        {submitError}
                      </Callout>
                    )}

                    <Field
                      name="business_name"
                      validate={(value) => {
                        if (!value) {
                          return 'Enter your business name.';
                        }

                        if (value.length < 2) {
                          return 'Enter a business name that contains more than one character.';
                        }

                        if (value.length > 50) {
                          return 'Enter a business name less than 50 characters.';
                        }
                      }}
                    >
                      {({ input, meta }) => (
                        <Box>
                          <Input
                            {...input}
                            label={
                              <FormattedMessage
                                id="SignupModal.businessNameLabel"
                                defaultMessage="Business name"
                              />
                            }
                            error={meta.touched && meta.error}
                            type="text"
                          />
                        </Box>
                      )}
                    </Field>

                    <Field
                      name="type"
                      validate={(value) => {
                        if (!value && businessTypes.length) {
                          return 'Select a business type.';
                        }
                      }}
                    >
                      {({ input, meta }) => (
                        <Box>
                          <Autocomplete
                            {...input}
                            label="Business type"
                            error={meta.touched && meta.error}
                            type="text"
                            itemToString={(v) =>
                              businessTypes.find((t) => t.value === v)?.label ||
                              ''
                            }
                            items={businessTypes}
                          />
                        </Box>
                      )}
                    </Field>

                    {values.type === 'other' && (
                      <Field
                        name="other_type"
                        validate={(value) => {
                          if (!value) {
                            return 'What is the nature of your business?';
                          }
                        }}
                      >
                        {({ input, meta }) => (
                          <Box gridColumn="1 / -1">
                            <Input
                              {...input}
                              label="Other business type"
                              error={meta.touched && meta.error}
                              type="text"
                            />
                          </Box>
                        )}
                      </Field>
                    )}

                    <Field
                      name="firstname"
                      validate={(value) => {
                        if (!value) {
                          return 'Enter a firstname.';
                        }

                        if (value.length < 2) {
                          return 'Enter a firstname that contains more than one character.';
                        }

                        if (value.length > 50) {
                          return 'Enter a firstname less than 50 characters.';
                        }
                      }}
                    >
                      {({ input, meta }) => (
                        <Box>
                          <Input
                            {...input}
                            label={
                              <FormattedMessage
                                id="SignupModal.firstname"
                                defaultMessage="First name"
                              />
                            }
                            error={meta.touched && meta.error}
                            type="text"
                          />
                        </Box>
                      )}
                    </Field>

                    <Field
                      name="lastname"
                      validate={(value) => {
                        if (!value) {
                          return 'Enter a lastname.';
                        }

                        if (value.length < 2) {
                          return 'Enter a lastname that contains more than one character.';
                        }

                        if (value.length > 50) {
                          return 'Enter a lastname less than 50 characters.';
                        }
                      }}
                    >
                      {({ input, meta }) => (
                        <Box>
                          <Input
                            {...input}
                            label={
                              <FormattedMessage
                                id="SignupModal.lastname"
                                defaultMessage="Last name"
                              />
                            }
                            error={meta.touched && meta.error}
                            type="text"
                          />
                        </Box>
                      )}
                    </Field>

                    <Field
                      name="email"
                      validate={(value: string) => {
                        if (!value) {
                          return 'Enter an email address.';
                        }
                        if (!isEmail(value)) {
                          return 'Enter a valid email address.';
                        }
                      }}
                    >
                      {({ input, meta }) => (
                        <Box gridColumn="1 / -1">
                          <Input
                            {...input}
                            label={
                              <FormattedMessage
                                id="SignupModal.email"
                                defaultMessage="Email"
                              />
                            }
                            error={meta.touched && meta.error}
                            type="email"
                          />
                        </Box>
                      )}
                    </Field>

                    <Field
                      name="cellphone"
                      validate={(value) => {
                        if (!value) {
                          return 'Enter a phone number.';
                        } else if (!isValidNumber(value)) {
                          return 'Enter a valid phone number.';
                        }
                      }}
                    >
                      {({ input, meta }) => (
                        <Box gridColumn="1 / -1">
                          <Label mb={2} invalid={meta.touched && meta.error}>
                            <FormattedMessage
                              id="SignupModal.businessTelephoneLabel"
                              defaultMessage="Phone number"
                            />
                          </Label>

                          <PhoneInput
                            {...input}
                            invalid={meta.touched && meta.error}
                          />
                          {meta.error && meta.touched && (
                            <FormError>{meta.error}</FormError>
                          )}
                        </Box>
                      )}
                    </Field>

                    {values.offer_description ? (
                      <Callout color="success" gridColumn="1 / -1">
                        <Text display="inline" fontWeight="bold">
                          Discount
                        </Text>
                        : {values.offer_description}
                        <CloseButton
                          position="absolute"
                          top={'16px'}
                          right={'16px'}
                          isDark={true}
                          onClick={() => {
                            form.change('offer_description', null);
                            form.change('offer_id', null);
                            form.change('referring_business_id', null);
                          }}
                        />
                      </Callout>
                    ) : (
                      <Grid gridColumn="1 / -1">
                        {!isDiscountCodeOpen ? (
                          <Box>
                            <Flex justifyContent="flex-end">
                              <Link
                                as="button"
                                type="button"
                                onClick={() => setIsDiscountCodeOpen(true)}
                              >
                                Have a discount code?
                              </Link>
                            </Flex>
                          </Box>
                        ) : (
                          <Form
                            initialValues={initialOfferValues}
                            onSubmit={(values) => {
                              return axios
                                .post(
                                  `${settings.api2Root}/system-offer-code-validate`,
                                  { value: values.discount_code },
                                  { skipErrorResponseIntercept: true }
                                )
                                .then(
                                  ({ data }) => {
                                    for (const key in data) {
                                      // @ts-ignore
                                      form.change(key, data[key]);
                                    }

                                    // setIsDiscountCodeOpen(false);
                                  },
                                  (error) => {
                                    if (
                                      typeof error.response?.data?.detail ===
                                      'string'
                                    ) {
                                      return {
                                        discount_code:
                                          error.response.data.detail,
                                      };
                                    }
                                    return {
                                      discount_code: 'Invalid code',
                                    };
                                  }
                                );
                            }}
                          >
                            {({ handleSubmit, errors }) => {
                              return (
                                <Box>
                                  <Field
                                    name="discount_code"
                                    validate={(value) => {
                                      if (!value) {
                                        return 'You cannot apply a blank discount code';
                                      }
                                    }}
                                  >
                                    {({ input, meta }) => (
                                      <>
                                        <Flex
                                          mb={2}
                                          alignItems="center"
                                          justifyContent="space-between"
                                        >
                                          <Label>Discount code</Label>
                                          <Link
                                            as="button"
                                            type="button"
                                            fontSize={1}
                                            onClick={() => {
                                              setIsDiscountCodeOpen(false);
                                              form.change('offer_id', null);
                                              form.change(
                                                'referring_business_id',
                                                null
                                              );
                                              form.change(
                                                'offer_description',
                                                null
                                              );
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faTimes} />
                                          </Link>
                                        </Flex>

                                        <InputGroup>
                                          <Input {...input} />
                                          <Button
                                            type="submit"
                                            color="secondary"
                                            onClick={handleSubmit}
                                            id="discount-code-apply-button"
                                          >
                                            Apply
                                          </Button>
                                        </InputGroup>
                                        {(meta.error || meta.submitError) &&
                                          meta.touched && (
                                            <FormError>
                                              {meta.error || meta.submitError}
                                            </FormError>
                                          )}
                                      </>
                                    )}
                                  </Field>
                                </Box>
                              );
                            }}
                          </Form>
                        )}
                      </Grid>
                    )}

                    <Field
                      name="terms_of_service"
                      type="checkbox"
                      validate={(v) => {
                        if (!v) {
                          return 'Accept our terms of service.';
                        }
                      }}
                    >
                      {({ input, meta }) => (
                        <Box gridColumn="1/-1">
                          <LabeledCheckbox
                            {...input}
                            // invalid={meta.touched && meta.error}
                            label={
                              <>
                                I agree to Bookem's{' '}
                                <Link
                                  as="button"
                                  type="button"
                                  onClick={() => setIsTOSOpen(true)}
                                  fontWeight="heading"
                                >
                                  terms of service.
                                </Link>
                              </>
                            }
                          />
                          {meta.error && meta.touched && (
                            <FormError>{meta.error}</FormError>
                          )}
                        </Box>
                      )}
                    </Field>
                    <Button
                      gridColumn="1 / -1"
                      id="signup-submit-button-1"
                      width="100%"
                      type="submit"
                      color="brand1"
                      loading={submitting}
                      disabled={submitting || !isRecaptchaReady}
                    >
                      <FormattedMessage
                        id="SignupModal.submitButton"
                        defaultMessage="Sign up"
                      />
                    </Button>
                  </Grid>

                  <ForgotPasswordModal
                    isOpen={isForgotPasswordModalOpen}
                    close={() => setIsForgotPasswordModalOpen(false)}
                    initialEmail={values.email}
                  />
                </Grid>
              );
            }}
          </Form>

          <StandardModal
            title="Terms of Service"
            isOpen={isTOSOpen}
            close={() => setIsTOSOpen(false)}
          >
            {() => (
              <>
                <TOS />
              </>
            )}
          </StandardModal>
        </>
      )}
    </Modal>
  );
};

export default SignupModal;
