import Color from 'color';
import React from 'react';
import { Box, BoxProps } from 'shared/grid';
import FormError from '../form-error';
import { invalidInput } from '../input-style';
import Label from '../label';
import { Theme, ThemeContext } from '@emotion/react';

interface ExtraStyledSelectProps {
  invalid?: boolean;
}

type StyledSelectProps = Omit<
  React.HTMLProps<HTMLSelectElement>,
  keyof BoxProps
> &
  ExtraStyledSelectProps &
  BoxProps;

export const selectArrowStyle = (theme) => ({
  backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='16' viewBox='0 0 32 16'><polygon points='0,0 32,0 16,16' style='fill: ${Color(
    theme.colors.text
  )
    .rgb()
    .string()
    .replace('%', '%29')}'></polygon></svg>")`,

  backgroundOrigin: 'content-box',
  backgroundPosition: `right -${16}px center`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '9px 6px',
});

export const StyledSelect = React.forwardRef<
  HTMLSelectElement,
  StyledSelectProps
>(function StyledSelect({ invalid, as = 'select', ...props }, ref) {
  const theme = React.useContext<Theme>(ThemeContext as any);
  return (
    <Box
      ref={ref}
      tx="inputs"
      variant="default"
      as={as}
      __css={{
        ...(invalid ? invalidInput : {}),
        '&::-ms-expand': {
          display: 'none',
        },

        '&[multiple]': {
          height: 'auto',
          backgroundImage: 'none',
        },
        paddingRight: `${32}px !important`,
        fontSize: 2,
        ...selectArrowStyle(theme),
      }}
      {...props}
    />
  );
});

type SelectProps = {
  label?: any;
  error?: string;
} & React.InputHTMLAttributes<HTMLSelectElement> &
  React.LabelHTMLAttributes<HTMLLabelElement> &
  StyledSelectProps;

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  function Select(props, ref) {
    const [isFocused, setFocus] = React.useState(false);
    const { label, onFocus, onBlur, error, invalid } = props;

    return (
      <>
        {props.label && (
          <Label mb={2} invalid={invalid || !!error} isFocused={isFocused}>
            {label}
          </Label>
        )}
        <StyledSelect
          ref={ref}
          {...props}
          invalid={invalid || !!error}
          onFocus={(e) => {
            setFocus(true);
            onFocus && onFocus(e);
          }}
          onBlur={(e) => {
            setFocus(false);
            onBlur && onBlur(e);
          }}
        />
        {error && <FormError>{error}</FormError>}
      </>
    );
  }
);

export default Select;
