import React from 'react';

let recaptcha;

export const getRecaptcha = () => {
  if (!__RECAPTCHA_SITE_KEY__) {
    return Promise.resolve<any>(null);
  }

  //   throw Error('Recaptcha v3 is not supported', );

  if (!recaptcha) {
    return new Promise<any>((resolve) => {
      const script = document.createElement('script');
      //   script.src = `https://www.google.com/recaptcha/api.js?render=${__RECAPTCHA_SITE_KEY__}`;
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${__RECAPTCHA_SITE_KEY__}`;
      script.async = true;
      script.defer = true;
      //   script.onload = () => {
      //     window['grecaptcha'].ready(() => {
      //       resolve(window['grecaptcha']);
      //     });
      //   };

      script.onload = () => {
        window['grecaptcha'].enterprise.ready(() => {
          resolve(window['grecaptcha'].enterprise);
        });
      };
      document.body.appendChild(script);
    });
  } else {
    return Promise.resolve<any>(recaptcha);
  }
};

export const getRecaptchaToken = (action) => {
  if (!__RECAPTCHA_SITE_KEY__) {
    return null;
  }

  return getRecaptcha().then((r) =>
    r.execute(__RECAPTCHA_SITE_KEY__, { action })
  );
};

export const useIsRecaptchaReady = () => {
  const [isReady, setIsReady] = React.useState(false);

  React.useEffect(() => {
    if (!__RECAPTCHA_SITE_KEY__) {
      setIsReady(true);
    }

    getRecaptcha().then(() => setIsReady(true));
  }, []);

  return isReady;
};
