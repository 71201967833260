export const dropdownSx = {
  border: `1px solid rgba(27, 31, 35, 0.15)`,
  boxShadow: `large`,
  backgroundColor: 'background',
  zIndex: 6002,
  maxHeight: '15rem',
  overflowY: 'auto' as any,
  borderBottomLeftRadius: 1,
  borderBottomRightRadius: 1,
};
