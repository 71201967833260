import settings from '../../settings';
import axios from '../../anaxios';
import {
  BOOKING_CLEAR,
  BOOKING_FETCH_FAILED,
  BOOKING_FETCH_REQUESTED,
  BOOKING_FETCH_SUCCEEDED,
  BOOKING_PATCH_FAILED,
  BOOKING_PATCH_REQUEST,
  BOOKING_PATCH_SUCCEEDED,
  BOOKING_SAVE_REQUESTED,
  BOOKING_SAVE_SUCCEEDED,
  BOOKING_SAVE_FAILED,
} from './action-types';
import { CLIENT_FETCH_SUCCEEDED } from '../clients/action-types';
import { updatePreferences } from 'shared/redux/user-preferences/actions';
import { INVOICE_FETCH_SUCCEEDED } from '../invoices/action-types';

const cache = {};

export const clearBookings = { type: BOOKING_CLEAR };

interface FetchBookingsProps {
  businessId: string;
  limit?: number;
  offset?: number;
  exclude?: string[];
  from?: string;
  until?: string;
  clientId?: string;
  status?: string;
  search?: string;
  recurrenceStartBookingId?: string;
  useCache?: boolean;
}

export const fetchBookings = (
  {
    businessId,
    limit,
    offset,
    exclude = [],
    from,
    until,
    clientId,
    status,
    search,
    recurrenceStartBookingId,
    useCache = true,
  }: FetchBookingsProps,
  changeStatus = true
) => {
  // if (!businessId) {
  //   throw new Error('no businessid');
  // }
  return (dispatch, getState) => {
    const cacheKey = JSON.stringify({
      businessId,
      limit,
      offset,
      // exclude,
      from,
      until,
      clientId,
      status,
      search,
    });

    if (useCache) {
      const lastTimestamp = cache[cacheKey] || 0;
      const exp = new Date().getTime() - 1000 * 60;
      if (lastTimestamp > exp) {
        return Promise.resolve();
      }
    }

    const state = getState();
    const token = state.session.token;
    const isSessionValid = state.session.tokenExp > new Date().getTime() / 1000;

    if (changeStatus) {
      dispatch({ type: BOOKING_FETCH_REQUESTED });
    }

    return axios
      .get(`${settings.api2Root}/bookings`, {
        params: {
          business_id: businessId,
          limit,
          offset,
          // exclude: exclude.join(','),
          from,
          until,
          client_id: clientId,
          status,
          search,
          recurrence_start_booking_id: recurrenceStartBookingId,
        },
        headers: isSessionValid
          ? { Authorization: `Bearer ${token}` }
          : undefined,
      })
      .then(({ data }) => {
        dispatch({
          type: BOOKING_FETCH_SUCCEEDED,
          payload: data.data
            .filter((booking) => !exclude.includes(booking.id))
            .reduce((acc, booking) => {
              acc[booking.id] = booking;
              return acc;
            }, {}),
        });

        dispatch({
          type: CLIENT_FETCH_SUCCEEDED,
          payload: data.clients.reduce(
            (agg, cur) => (agg[cur.id] = cur) && agg,
            {}
          ),
        });

        dispatch({
          type: INVOICE_FETCH_SUCCEEDED,
          payload: data.invoices.reduce(
            (agg, cur) => (agg[cur.id] = cur) && agg,
            {}
          ),
        });

        cache[cacheKey] = new Date().getTime();
        return data;
      })
      .catch((error) => {
        dispatch({ type: BOOKING_FETCH_FAILED });
        return Promise.reject(error);
      });
  };
};

export const fetchBooking = (bookingId) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;

    const isSessionValid = state.session.tokenExp > new Date().getTime() / 1000;

    dispatch({ type: BOOKING_FETCH_REQUESTED });

    return axios
      .get(`${settings.api2Root}/bookings/${bookingId}`, {
        headers: isSessionValid
          ? { Authorization: `Bearer ${token}` }
          : undefined,
      })
      .then(({ data }) => {
        const bookings = {
          [data.id]: data,
        };

        // Load all business data
        if (!state.businesses.data[data.business_id]) {
          dispatch(updatePreferences({ currentBusinessId: data.business_id }));
        }

        dispatch({ type: BOOKING_FETCH_SUCCEEDED, payload: bookings });
        return data;
      })
      .catch((error) => {
        dispatch({ type: BOOKING_FETCH_FAILED });
        return Promise.reject(error);
      });
  };
};

export const saveBooking = (booking) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;
    const isSessionValid = state.session.tokenExp > new Date().getTime() / 1000;
    dispatch({ type: BOOKING_SAVE_REQUESTED });

    return axios
      .put(`${settings.api2Root}/bookings/${booking.id}`, booking, {
        headers: isSessionValid
          ? { Authorization: `Bearer ${token}` }
          : undefined,
      })
      .then(({ data }) => {
        dispatch({
          type: BOOKING_SAVE_SUCCEEDED,
          payload: { [data.id]: data },
        });
        return data;
      })
      .catch((error) => {
        dispatch({ type: BOOKING_SAVE_FAILED, payload: error });
        return Promise.reject(error);
      });
  };
};

export const patchBooking = (booking) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;
    const isSessionValid = state.session.tokenExp > new Date().getTime() / 1000;
    dispatch({ type: BOOKING_PATCH_REQUEST });

    return axios
      .patch(`${settings.api2Root}/bookings/${booking.id}`, booking, {
        headers: isSessionValid
          ? { Authorization: `Bearer ${token}` }
          : undefined,
      })
      .then(({ data }) => {
        const bookings = { [data.id]: data };
        dispatch({ type: BOOKING_PATCH_SUCCEEDED, payload: bookings });
        return booking;
      })
      .catch((error) => {
        dispatch({ type: BOOKING_PATCH_FAILED, payload: error });
        return Promise.reject(error);
      });
  };
};

export const createBooking = (booking) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;
    const isSessionValid = state.session.tokenExp > new Date().getTime() / 1000;
    dispatch({ type: BOOKING_SAVE_REQUESTED });

    return axios
      .post(`${settings.api2Root}/bookings`, booking, {
        headers: isSessionValid
          ? { Authorization: `Bearer ${token}` }
          : undefined,
      })
      .then(({ data }) => {
        const bookings = { [data.id]: data };
        dispatch({ type: BOOKING_SAVE_SUCCEEDED, payload: bookings });
        return data;
      })
      .catch((error) => {
        dispatch({ type: BOOKING_FETCH_FAILED, payload: error });
        return Promise.reject(error);
      });
  };
};
