import { Theme, ThemeContext } from '@emotion/react';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  shift,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { dropdownSx } from 'shared/dropdown-style';
import { Box, Flex, FlexProps, SxStyleProp } from 'shared/grid';
import { useDebounce } from 'shared/hooks';
import Input from 'shared/input';
import Label from 'shared/label';
import Portal from 'shared/portal';
import { selectArrowStyle } from 'shared/select';
import { Text } from 'shared/typography';
import rot13 from 'shared/utils/rot13';
import { highlight, score } from '../search';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'shared/utils/constants';

interface ItemType {
  value: any;
  label: string; // | React.JSX.Element;
  iconElement?: React.JSX.Element;
  extra?: any;
  disabled?: boolean;
  groupId?: string;
  score?: number;
  sx?: SxStyleProp;
}

interface Group {
  label: string;
  iconElement?: React.JSX.Element;
}

export interface AutocompleteProps extends FlexProps {
  onChange: (any) => string | void;
  value: any;
  itemToString: (any) => string;
  items: ItemType[];
  staticItems?: readonly ItemType[];
  staticItemsEnd?: readonly ItemType[];
  className?: string;
  onFocus?: (any) => void;
  onBlur?: (any) => void;
  placeholder?: string;
  tabIndex?: number;
  name?: string;
  onInputValueChange?: (any) => void;
  changeOpenState?: (boolean) => void;
  invalid?: boolean;
  error?: any;
  isLoading?: boolean;
  label?: any;
  labelId?: string;
  disabled?: boolean;
  usePortal?: boolean;
  isClearable?: boolean;
  suffix?: any;
  prefix?: any;
  truncate?: number;
  groups?: Record<string, Group>;
  searchExtra?: boolean;
}

const Autocomplete = React.forwardRef(function Autocomplete(
  {
    onChange,
    value,
    itemToString,
    items,
    staticItems = EMPTY_ARRAY,
    staticItemsEnd = EMPTY_ARRAY,
    className = '',
    onFocus,
    onBlur,
    placeholder,
    tabIndex,
    name,
    invalid,
    error,
    isLoading = false,
    label,
    labelId,
    disabled,
    usePortal = false,
    isClearable = false,
    onInputValueChange,
    changeOpenState,
    suffix,
    prefix,
    truncate = 0,
    variant = 'default',
    groups = EMPTY_OBJECT,
    searchExtra = false,
    ...rest
  }: AutocompleteProps,
  ref
) {
  staticItems = staticItems || EMPTY_ARRAY;
  staticItemsEnd = staticItemsEnd || EMPTY_ARRAY;

  const [open, setOpen_] = React.useState(false);
  const [inputValue, setInputValue_] = React.useState(itemToString(value));

  const setInputValue = React.useCallback(
    (v) => {
      setInputValue_(v);
      if (onInputValueChange) {
        onInputValueChange(v);
      }
    },
    [onInputValueChange]
  );

  const setOpen = React.useCallback(
    (v) => {
      setOpen_(v);
      changeOpenState?.(v);
      if (!v) {
        setInputValue(itemToString(value));
      }
    },
    [changeOpenState, itemToString, setInputValue, value]
  );

  React.useEffect(() => {
    setInputValue(itemToString(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInputValue, value]);

  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  const listRef = React.useRef<Array<HTMLElement | null>>([]);

  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: setOpen,
    middleware: [
      flip({ padding: 10 }),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
        padding: 10,
      }),
      shift(),
    ],
    placement: 'bottom-start',
    strategy: 'absolute',
  });

  const role = useRole(context, { role: 'listbox' });
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [role, dismiss, listNav]
  );

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setInputValue(value);
    if (value) {
      setActiveIndex(0);
    }
  }

  const debouncedInputValue = useDebounce(
    inputValue?.startsWith?.('0') ? inputValue.substring(1) : inputValue,
    items.length > 1000 ? 500 : 0
  );

  const searchString =
    debouncedInputValue?.[0] == '0'
      ? debouncedInputValue.substring(1)
      : debouncedInputValue;

  const filteredItems = React.useMemo(() => {
    let filteredItems: ItemType[] = items;

    if (searchString) {
      filteredItems = items
        .map((item) => ({
          ...item,
          score: score(
            item.label +
              (searchExtra ? (item.extra ? ` ${item.extra}` : '') : ''),
            searchString
          ),
        }))
        .filter((item) => item.score > 0);

      filteredItems = filteredItems.sort((a, b) =>
        a.score > b.score ? -1 : 1
      );
    }

    filteredItems = filteredItems.filter(
      (_, idx) => !truncate || idx < truncate
    );
    return filteredItems;
  }, [items, searchString, truncate]);

  const [idxToGroupHeading, groupedItems] = React.useMemo(() => {
    const groupMap = filteredItems.reduce((groups, item) => {
      const key = item.groupId || 'default';
      groups[key] = groups[key] || [];
      groups[key].push(item);
      return groups;
    }, {});

    const groupList = Object.values<any>(groupMap).sort((a, b) =>
      a[0].score < b[0].scored ? -1 : 1
    );

    if (groupList.length < 2) {
      return [{}, staticItems.concat(filteredItems).concat(staticItemsEnd)];
    }

    let itemIdx = 0;

    const idxToGroupHeading = {};
    const groupedItems = [];

    for (const group of groupList) {
      for (const staticItem of staticItems) {
        if (staticItem.groupId === group[0].groupId) {
          group.unshift(staticItem);
        }
      }

      for (const staticItem of staticItemsEnd) {
        if (staticItem.groupId === group[0].groupId) {
          group.push(staticItem);
        }
      }
    }

    for (const group of groupList) {
      for (let i = 0; i < group.length; i++) {
        const item = group[i];
        if (i === 0) {
          idxToGroupHeading[itemIdx + staticItems.length] =
            groups[item.groupId];
        }
        groupedItems.push(item);
        itemIdx++;
      }
    }

    return [idxToGroupHeading, groupedItems];
  }, [filteredItems, groups, staticItems, staticItemsEnd]);

  const theme = React.useContext<Theme>(ThemeContext as any);
  const MaybePortal = usePortal ? Portal : React.Fragment;

  return (
    <>
      <Box
        {...rest}
        position="relative"
        className={className}
        ref={ref}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            console.log('enter dstop');
            e.stopPropagation();
          }
        }}
        title={itemToString(value)}
      >
        {label && (
          <>
            <Label as={Box} mb={2}>
              {label}
            </Label>
          </>
        )}
        <input className="decoy" type="text" style={{ display: 'none' }} />
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              right: '38px',
              bottom: '10px',
              pointerEvents: 'none',
              animation: 'fa-spin 1s infinite linear',
              zIndex: 2,
            }}
          >
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          </Box>
        )}
        <Input
          invalid={invalid}
          error={error}
          {...getReferenceProps({
            ref: refs.setReference,
            onChange: onInputChange,

            value: inputValue,
            name: name && rot13(name),
            tabIndex,

            disabled,
            placeholder: /*itemToString(value) || */ placeholder || 'Search...',
            'aria-autocomplete': 'list',
            onKeyDown(event) {
              event.stopPropagation();
              if (event.key === 'Enter') {
                event.preventDefault();
              }

              if (
                event.key === 'Enter' &&
                activeIndex != null &&
                groupedItems[activeIndex]
              ) {
                const close =
                  onChange(groupedItems[activeIndex].value) !== 'noclose';

                if (close) {
                  setOpen(false);
                }
                setActiveIndex(null);
              } else {
                setOpen(true);
              }
            },
            onFocus(e) {
              onFocus?.(e);
              e.target['select']();
            },
            onBlur(e) {
              onBlur?.(e);
            },
            onClick(e) {
              if (!open) {
                setOpen(true);
                setInputValue('');
              }
            },
          })}
          prefix={prefix}
          suffix={suffix}
          variant={variant}
          type="text"
          autoCapitalize="off"
          autoCorrect="off"
          autoComplete="one-time-code"
          pr="32px"
          sx={selectArrowStyle(theme)}
        />
        {value && isClearable ? (
          <Box
            as="button"
            type="button"
            onClick={() => onChange('')}
            sx={{
              position: 'absolute',
              right: '30px',
              top: '33%',
              cursor: 'pointer',
            }}
          >
            ✕
          </Box>
        ) : null}
        <MaybePortal>
          {open && (
            <FloatingFocusManager context={context} initialFocus={-1}>
              <Box
                sx={dropdownSx}
                {...getFloatingProps({
                  ref: refs.setFloating,
                  style: {
                    ...floatingStyles,
                  },
                })}
              >
                {groupedItems.map((item, index) => (
                  <React.Fragment key={`${item}${index}`}>
                    {idxToGroupHeading[index]?.label && (
                      <Flex
                        px={2}
                        py={2}
                        fontWeight="heading"
                        color="gray.6"
                        alignItems="center"
                      >
                        {idxToGroupHeading[index]?.iconElement && (
                          <Box mr={2}>
                            {idxToGroupHeading[index]?.iconElement}{' '}
                          </Box>
                        )}
                        {idxToGroupHeading[index].label}
                      </Flex>
                    )}

                    <Box
                      key={item.value}
                      {...(!item.disabled
                        ? getItemProps({
                            ref(node) {
                              listRef.current[index] = node;
                            },
                            onClick(e) {
                              const close = onChange(item.value) !== 'noclose';
                              if (close) {
                                setOpen(false);
                              }

                              refs.domReference.current?.focus();
                            },
                          })
                        : {})}
                      sx={{
                        padding: '0.5rem',
                        backgroundColor:
                          activeIndex === index ? 'primary_.5' : 'transparent',
                        fontWeight: value === item.value ? 'heading' : 'normal',
                        fontFamily: 'body',
                        cursor: 'pointer',
                        color: item.disabled
                          ? 'gray.4'
                          : activeIndex === index
                            ? 'white'
                            : 'inherit',
                      }}
                    >
                      <Flex alignItems="center">
                        {item.iconElement && (
                          <Box sx={item.sx} mr={2}>
                            {item.iconElement}
                          </Box>
                        )}
                        <Text
                          sx={item.sx}
                          dangerouslySetInnerHTML={{
                            __html: searchString
                              ? highlight(item.label, searchString)
                              : item.label,
                          }}
                        />
                      </Flex>
                      {item.extra && (
                        <Text
                          dangerouslySetInnerHTML={{
                            __html:
                              searchString &&
                              searchExtra &&
                              typeof item.extra === 'string'
                                ? highlight(item.extra, searchString)
                                : item.extra,
                          }}
                        />
                      )}
                    </Box>
                  </React.Fragment>
                ))}

                {filteredItems.length === 0 &&
                  staticItems.length === 0 &&
                  staticItemsEnd.length == 0 && (
                    <Text
                      sx={{
                        padding: '0.5rem',
                        backgroundColor: 'transparent',
                        fontWeight: 'heading',
                        fontFamily: 'body',
                      }}
                    >
                      No matches
                    </Text>
                  )}
              </Box>
            </FloatingFocusManager>
          )}
        </MaybePortal>
      </Box>
    </>
  );
});

// Autocomplete.whyDidYouRender = true;

export default Autocomplete;
