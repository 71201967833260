export const BOOKING_FETCH_REQUESTED = 'BOOKING_FETCH_REQUESTED';
export const BOOKING_FETCH_SUCCEEDED = 'BOOKING_FETCH_SUCCEEDED';
export const BOOKING_FETCH_FAILED = 'BOOKING_FETCH_FAILED';

export const BOOKING_SAVE_REQUESTED = 'BOOKING_SAVE_REQUESTED';
export const BOOKING_SAVE_SUCCEEDED = 'BOOKING_SAVE_SUCCEEDED';
export const BOOKING_SAVE_FAILED = 'BOOKING_SAVE_FAILED';

export const BOOKING_PATCH_REQUEST = 'BOOKING_PATCH_REQUEST';
export const BOOKING_PATCH_SUCCEEDED = 'BOOKING_PATCH_SUCCEEDED';
export const BOOKING_PATCH_FAILED = 'BOOKING_PATCH_FAILED';

export const BOOKING_CLEAR = 'BOOKING_CLEAR';

export const BOOKING_UPDATED = 'BOOKING_UPDATED';
