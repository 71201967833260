import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'shared/anaxios';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import isEmail from 'shared/utils/is-email';
import Button from 'shared/button';
import Callout from 'shared/callout';
import { Box, Grid } from 'shared/grid';
import Input from 'shared/input';
import { StandardModal } from 'shared/modal';
import settings from 'shared/settings';
import { addToast } from 'shared/toaster/redux/actions';
import { getScrollToErrors } from 'shared/utils/form';
import { useDispatch } from 'react-redux';

const PasswordRecoverModal = ({ isOpen, close, initialEmail = '' }) => {
  const dispatch = useDispatch();
  const [recoveredEmail, setRecoveredEmail] = React.useState(false);
  const decorators = React.useMemo(() => [getScrollToErrors()], []);
  const initialValues = React.useMemo(
    () => ({ email: initialEmail }),
    [initialEmail]
  );

  return (
    <StandardModal
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={true}
      isOpen={isOpen}
      close={close}
      title={
        <FormattedMessage
          id="PasswordRecoverModal.title"
          defaultMessage="Password recovery"
        />
      }
    >
      {() => (
        <>
          {!recoveredEmail ? (
            <Form
              initialValues={initialValues}
              decorators={decorators}
              onSubmit={({ email }) => {
                return axios
                  .post(
                    `${settings.api2Root}/account-recovery`,
                    {
                      email,
                    },
                    { skipErrorResponseIntercept: true }
                  )
                  .then(({ data }) => {
                    setRecoveredEmail(email);
                  })
                  .catch(({ response }) => {
                    if (response.status === 404) {
                      dispatch(
                        addToast(`No account with ${email} found`, 'warning')
                      );
                      return;
                    } else {
                      dispatch(
                        addToast(
                          'An error occurred while trying to recover your password',
                          'error'
                        )
                      );
                    }
                  });
              }}
            >
              {({ handleSubmit, submitError, submitting }) => (
                <form name="forgot-password" onSubmit={handleSubmit} noValidate>
                  <Grid>
                    {submitError && (
                      <Callout color="alert">{submitError}</Callout>
                    )}

                    <Field
                      name="email"
                      validate={(value) => {
                        if (!value) {
                          return 'Enter an email address';
                        }
                        if (!isEmail(value)) {
                          return 'Enter a valid email address';
                        }
                      }}
                    >
                      {({ input, meta }) => (
                        <Box>
                          <Input
                            {...input}
                            label="Enter your email address below to recover your password"
                            // label={
                            //   <FormattedMessage
                            //     id="PasswordRecoverModal.email"
                            //     defaultMessage="Email"
                            //   />
                            // }
                            error={meta.touched && meta.error}
                            type="email"
                          />
                        </Box>
                      )}
                    </Field>

                    <Button
                      width="100%"
                      type="submit"
                      loading={submitting}
                      disabled={submitting}
                    >
                      <FormattedMessage
                        id="PasswordRecoverModal.recoverButton"
                        defaultMessage="Recover"
                      />
                    </Button>
                  </Grid>
                </form>
              )}
            </Form>
          ) : (
            <Grid
              sx={{
                justifyItems: 'center',
              }}
            >
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="5x"
                className="success"
              />
              <Box as="p" fontWeight="heading">
                A recovery link has been sent to {recoveredEmail}.
              </Box>
              <Button as="a" onClick={close} width="100%">
                I will check my email
              </Button>
            </Grid>
          )}
        </>
      )}
    </StandardModal>
  );
};

export default PasswordRecoverModal;
