import merge from 'deepmerge';
import axios from '../../anaxios';
import settings from '../../settings';
import {
  USER_PREFERENCES_FETCH_SUCCEEDED,
  USER_PREFERENCES_UPDATE,
} from './action-types';
import { LocationState, UserPreferencesState } from './reducer';

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

type UpdateUserPreferencesData = Partial<
  Omit<UserPreferencesState, 'locationState'> & {
    locationState: Record<string, Partial<LocationState>>;
  }
>;

export const updatePreferences = (partial: UpdateUserPreferencesData) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;
    const userId = state.session.userId;
    const oldPreferences = state.userPreferences;
    const locations = state.locations.data;

    const next = merge(oldPreferences, partial, { arrayMerge: overwriteMerge });

    // Ensure we dont set bad combinations of business and location
    if (
      (partial.currentBusinessId &&
        !['-1', '0'].includes(partial.currentBusinessId)) ||
      partial.currentLocationId
    ) {
      const selectedLocation = locations[next.currentLocationId];
      if (selectedLocation?.business_id !== next.currentBusinessId) {
        next.currentLocationId = partial.currentLocationId = Object.values<any>(
          locations
        ).find((l) => l.business_id === next.currentBusinessId)?.id;
      }
    }

    if (partial.currentBusinessId === '-1') {
      next.currentLocationId = partial.currentLocationId = '';
    }

    if (JSON.stringify(oldPreferences) === JSON.stringify(next)) {
      return Promise.resolve(oldPreferences);
    }

    dispatch({
      type: USER_PREFERENCES_UPDATE,
      payload: partial,
    });

    if (userId) {
      return axios
        .patch(`${settings.api2Root}/user-preferences/${userId}`, partial, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch(() => {
          dispatch({ type: USER_PREFERENCES_UPDATE, payload: oldPreferences });
        });
    }
  };
};

export const fetchUserPreferences = ({ keepContext = false } = {}) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;
    const userId = state.session.userId;
    return axios
      .get(`${settings.api2Root}/user-preferences/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        if (keepContext) {
          data.currentBusinessId = state.userPreferences.currentBusinessId;
          data.currentLocationId = state.userPreferences.currentLocationId;
        }
        dispatch({ type: USER_PREFERENCES_FETCH_SUCCEEDED, payload: data });
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const setLocationPrefences =
  (data: Partial<LocationState>) => (dispatch, getState) => {
    const state: UserPreferencesState = getState().userPreferences;
    const locationId = state.currentLocationId;
    return dispatch(
      updatePreferences({ locationState: { [locationId]: data } })
    );
  };
