import { faChevronRight, faGlobe } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { default as NextLink } from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import DropdownSelect from 'shared/dropdown-select';
import { Box, Flex } from 'shared/grid';
import useComponentSize from 'shared/hooks/component-size';
import { openSignUpModal } from 'shared/redux/globals/actions';
import { languages } from 'shared/utils/constants';
import { useAppDispatch } from '../../store';
import { ScrollButton } from '../landing-shared';
import { TOPBAR_HEIGHT } from '.';

const MobileLinksAccordion = ({ text, links, close }) => {
  const contentRef = React.useRef(null);
  const { height: contentHeight } = useComponentSize(contentRef);
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Box py={3}>
      <Flex
        width="100%"
        as="button"
        type="button"
        justifyContent="space-between"
        alignItems="center"
        onClick={(e) => setIsOpen((v) => !v)}
      >
        <Box
          sx={{
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            overflowWrap: 'break-word',
            textAlign: 'left',
          }}
        >
          {text}
        </Box>

        <Box
          sx={{
            transition: 'transform 200ms ease-in-out',
            transitionDelay: isOpen ? undefined : '200ms',
            transform: isOpen ? 'rotate(90deg)' : 'rotate(0)',
          }}
        >
          <FontAwesomeIcon size="lg" icon={faChevronRight} />
        </Box>
      </Flex>

      <Box
        mt={isOpen ? 3 : 0}
        mb={isOpen ? 3 : 0}
        ml={3}
        sx={{
          transition: 'max-height 200ms ease-in-out, opacity 200ms ease-in-out',
          maxHeight: isOpen ? contentHeight : 0,
          opacity: isOpen ? 1 : 0,
          overflow: 'hidden',
        }}
      >
        <Box ref={contentRef}>
          <MobileLinks links={links} close={close} />
        </Box>
      </Box>
    </Box>
  );
};

const MobileLinks = ({ links, close }) => {
  return links.map((v, idx) => {
    if (v?.type === 'linkGroup') {
      return (
        <MobileLinksAccordion
          key={idx}
          links={v.links}
          text={v.text}
          close={close}
        />
      );
    } else if (v.href[0] === '#') {
      return (
        <ScrollButton
          key={idx}
          py={3}
          display="block"
          onClick={close}
          activeClass="active"
          to={v.href.substring(1)}
          spy={true}
          smooth={true}
          duration={500}
        >
          {v.text}
        </ScrollButton>
      );
    } else if (v.href[0] === '/') {
      return (
        <Box
          key={idx}
          href={v.href}
          py={3}
          onClick={close}
          display="block"
          as={NextLink}
        >
          {v.text}
        </Box>
      );
    } else {
      return (
        <Box
          onClick={close}
          py={3}
          key={idx}
          display="block"
          href={v.href}
          target="_blank"
          as="a"
        >
          {v.text}
        </Box>
      );
    }
  });
};

const MobileMenu = ({ isHome, close, links }) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { locale: language } = router;

  return (
    <Box
      sx={{
        top: TOPBAR_HEIGHT,
        left: 0,
        height: `calc(100vh - ${TOPBAR_HEIGHT})`,
        width: '100%',
        position: 'fixed',
        zIndex: 999,
        backgroundColor: '#fff',
        overflowY: 'auto',
      }}
    >
      <Box>
        <Box
          mx={3}
          sx={{
            'a, button': {
              textTransform: 'uppercase',
              fontFamily: 'heading',
              fontWeight: 'heading',
            },
          }}
        >
          <MobileLinks links={links} close={close} />

          <Box
            py={3}
            as="button"
            // display="block"
            onClick={() => {
              close();
              dispatch(openSignUpModal());
            }}
          >
            <FormattedMessage
              id="LandingPage.tryIt"
              defaultMessage="Get started"
            />
          </Box>

          <DropdownSelect
            id="language-selector"
            value={language}
            items={Object.entries(languages).map(([k, v]) => ({
              value: k,
              label: v,
            }))}
            onChange={(v) => {
              router.push(router.asPath, router.asPath, { locale: v });
            }}
            itemToString={(item) => languages[item]}
            renderToggle={(props, { displayString }) => (
              <Box py={3} display="block" as="button" {...props}>
                <FontAwesomeIcon icon={faGlobe} /> {displayString}
              </Box>
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MobileMenu;
