import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Box, BoxProps } from '../grid';
import { Theme, ThemeContext } from '@emotion/react';
import { themeGet } from '@styled-system/theme-get';

interface CalloutProps {
  color?: string;
  size?: 'small' | 'medium' | 'large';
  theme?: Theme;
}

type Props = CalloutProps & BoxProps;

export const Callout = React.forwardRef<HTMLDivElement, Props>(function Callout(
  { color, ...props },
  ref
) {
  const theme = React.useContext<Theme>(ThemeContext as any);
  const color_ = (color || 'primary').split('.')[0];

  const colorScaleName =
    {
      success: 'green',
      warning: 'yellow',
      alert: 'red',
      primary: 'primary_',
    }[color_] || color_;

  const isDark = theme.mode === 'dark';

  const fontColor = themeGet(
    `colors.${colorScaleName}.9`,
    colorScaleName
  )({ theme });

  const backgroundColor = themeGet(
    `colors.${colorScaleName}.${isDark ? 3 : 0}`,
    colorScaleName
  )({ theme });

  return (
    <Box
      ref={ref}
      __css={{
        color: 'primary',
        borderRadius: 2,
        px: 3,
        py: 3,
        fontSize: 2,
        fontFamily: color !== 'white' ? 'heading' : 'body',
        position: 'relative',
        border: fontColor,
        backgroundColor,
        '&, & > svg': {
          color: fontColor,
        },
      }}
      {...props}
    />
  );
});

export const WarningBox = ({ close, children, ...rest }) => (
  <Callout
    {...rest}
    color="warning"
    sx={{
      display: 'flex',
      alignItems: 'center',
      a: {
        color: '#765b1e',
      },
    }}
  >
    <Box flexGrow={1}>{children}</Box>
    <Box as="button" className="close" onClick={close} alignItems="flex-start">
      <FontAwesomeIcon icon={faTimes} />
    </Box>
  </Callout>
);

export default Callout;
