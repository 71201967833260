const COMPLETE = 'complete',
  CANCELED = 'canceled';

function raf(task) {
  if ('requestAnimationFrame' in window) {
    return window.requestAnimationFrame(task);
  }

  setTimeout(task, 16);
}

function setElementScroll(element, x, y) {
  if (element.self === element) {
    element.scrollTo(x, y);
  } else {
    element.scrollLeft = x;
    element.scrollTop = y;
  }
}

function getTargetScrollLocation(scrollSettings, parent) {
  const align = scrollSettings.align;
  const target = scrollSettings.target;
  const targetPosition = target.getBoundingClientRect();
  let parentPosition;
  let x;
  let y;
  let differenceX;
  let differenceY;
  let targetWidth;
  let targetHeight;
  const leftAlign = align && align.left != null ? align.left : 0.5;
  const topAlign = align && align.top != null ? align.top : 0.5;
  const leftOffset = align && align.leftOffset != null ? align.leftOffset : 0;
  const topOffset = align && align.topOffset != null ? align.topOffset : 0;
  const leftScalar = leftAlign;
  const topScalar = topAlign;

  if (!target.parentNode) {
    return null;
  }

  if (scrollSettings.isWindow(parent)) {
    targetWidth = Math.min(targetPosition.width, parent.innerWidth);
    targetHeight = Math.min(targetPosition.height, parent.innerHeight);
    x =
      targetPosition.left +
      parent.pageXOffset -
      parent.innerWidth * leftScalar +
      targetWidth * leftScalar;
    y =
      targetPosition.top +
      parent.pageYOffset -
      parent.innerHeight * topScalar +
      targetHeight * topScalar;
    x -= leftOffset;
    y -= topOffset;
    differenceX = x - parent.pageXOffset;
    differenceY = y - parent.pageYOffset;
  } else {
    targetWidth = targetPosition.width;
    targetHeight = targetPosition.height;
    parentPosition = parent.getBoundingClientRect();
    const offsetLeft =
      targetPosition.left - (parentPosition.left - parent.scrollLeft);
    const offsetTop =
      targetPosition.top - (parentPosition.top - parent.scrollTop);
    x = offsetLeft + targetWidth * leftScalar - parent.clientWidth * leftScalar;
    y = offsetTop + targetHeight * topScalar - parent.clientHeight * topScalar;
    x -= leftOffset;
    y -= topOffset;
    x = Math.max(Math.min(x, parent.scrollWidth - parent.clientWidth), 0);
    y = Math.max(Math.min(y, parent.scrollHeight - parent.clientHeight), 0);
    differenceX = x - parent.scrollLeft;
    differenceY = y - parent.scrollTop;
  }

  return {
    x: x,
    y: y,
    differenceX: differenceX,
    differenceY: differenceY,
  };
}

function animate(parent) {
  const scrollSettings = parent._scrollSettings;

  if (!scrollSettings) {
    return;
  }

  const maxSynchronousAlignments = scrollSettings.maxSynchronousAlignments;

  const location = getTargetScrollLocation(scrollSettings, parent),
    time = Date.now() - scrollSettings.startTime,
    timeValue = Math.min((1 / scrollSettings.time) * time, 1);

  if (!location) {
    parent._scrollSettings = null;
    return scrollSettings.end(COMPLETE);
  }

  if (scrollSettings.endIterations >= maxSynchronousAlignments) {
    setElementScroll(parent, location.x, location.y);
    parent._scrollSettings = null;
    return scrollSettings.end(COMPLETE);
  }

  const easeValue = 1 - scrollSettings.ease(timeValue);

  setElementScroll(
    parent,
    location.x - location.differenceX * easeValue,
    location.y - location.differenceY * easeValue
  );

  if (time >= scrollSettings.time) {
    scrollSettings.endIterations++;
    return animate(parent);
  }

  raf(animate.bind(null, parent));
}

function defaultIsWindow(target) {
  return target.self === target;
}

function transitionScrollTo(target, parent, settings, callback) {
  const idle = !parent._scrollSettings;
  const lastSettings = parent._scrollSettings;
  const now = Date.now();
  let cancelHandler;
  const passiveOptions = { passive: true };

  if (lastSettings) {
    lastSettings.end(CANCELED);
  }

  function end(endType) {
    parent._scrollSettings = null;

    if (parent.parentElement && parent.parentElement._scrollSettings) {
      parent.parentElement._scrollSettings.end(endType);
    }

    if (settings.debug) {
      console.log('Scrolling ended with type', endType, 'for', parent);
    }

    callback(endType);
    if (cancelHandler) {
      parent.removeEventListener('touchstart', cancelHandler, passiveOptions);
      parent.removeEventListener('wheel', cancelHandler, passiveOptions);
    }
  }

  let maxSynchronousAlignments = settings.maxSynchronousAlignments;

  if (maxSynchronousAlignments == null) {
    maxSynchronousAlignments = 3;
  }

  parent._scrollSettings = {
    startTime: now,
    endIterations: 0,
    target: target,
    time: settings.time,
    ease: settings.ease,
    align: settings.align,
    isWindow: settings.isWindow || defaultIsWindow,
    maxSynchronousAlignments: maxSynchronousAlignments,
    end: end,
  };

  if (!('cancellable' in settings) || settings.cancellable) {
    cancelHandler = end.bind(null, CANCELED);
    parent.addEventListener('touchstart', cancelHandler, passiveOptions);
    parent.addEventListener('wheel', cancelHandler, passiveOptions);
  }

  if (idle) {
    animate(parent);
  }
}

function defaultIsScrollable(element) {
  return (
    'pageXOffset' in element ||
    ((element.scrollHeight !== element.clientHeight ||
      element.scrollWidth !== element.clientWidth) &&
      getComputedStyle(element).overflow !== 'hidden')
  );
}

function defaultValidTarget(parent_, parents_) {
  return true;
}

function findParentElement(el: Element) {
  if (el.assignedSlot) {
    return findParentElement(el.assignedSlot);
  }

  if (el.parentElement) {
    if (el.parentElement.tagName === 'BODY') {
      return el.parentElement.ownerDocument.defaultView;
    }
    return el.parentElement;
  }

  if (el.getRootNode) {
    const parent = el.getRootNode();
    if (parent.nodeType === 11) {
      return parent['host'];
    }
  }
}

interface Settings {
  time?: number;
  ease?: (number) => number;
  validTarget?: (_1: any, _2: any) => boolean;
  isScrollable?: (_1: any, _2: (_3: any) => boolean) => boolean;
  debug?: boolean;
  noopIfVisible?: boolean;
}

function isElementInViewport(el: Element) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /* or $(window).width() */
  );
}

export default function scrollIntoView(
  target: Element,
  settings: Settings = {}
) {
  return new Promise((resolve, reject) => {
    if (!target) {
      reject('No target');
      return;
    }
    let parent = findParentElement(target);
    let parents = 1;

    function done(endType) {
      parents--;
      if (!parents) {
        resolve(endType);
      }
    }

    if (settings.noopIfVisible && isElementInViewport(target)) {
      done(COMPLETE);
      return;
    }

    settings.time = isNaN(settings.time) ? 1000 : settings.time;
    settings.ease =
      settings.ease ||
      function (v) {
        return 1 - Math.pow(1 - v, v / 2);
      };

    const validTarget = settings.validTarget || defaultValidTarget;
    const isScrollable = settings.isScrollable;

    if (settings.debug) {
      console.log('About to scroll to', target);

      if (!parent) {
        console.error(
          'Target did not have a parent, is it mounted in the DOM?'
        );
      }
    }

    while (parent) {
      if (settings.debug) {
        console.log('Scrolling parent node', parent);
      }

      if (
        validTarget(parent, parents) &&
        (isScrollable
          ? isScrollable(parent, defaultIsScrollable)
          : defaultIsScrollable(parent))
      ) {
        parents++;
        transitionScrollTo(target, parent, settings, done);
      }

      parent = findParentElement(parent);

      if (!parent) {
        done(COMPLETE);
        break;
      }
    }
  });
}
